<template>
  <div>

    <b-row>
      <b-col md="12" class="mb-3 text-right">
        <b-button class="" variant="outline-primarySpore" @click="showFilter">
          <feather-icon icon="FilterIcon" class="mr-50"/>
          <span class="align-middle" v-if="!show"> Mostrar filtros </span>
          <span class="align-middle" v-else > Ocultar filtros </span>
        </b-button>
      </b-col>
    </b-row>

    <b-card no-body v-if="show">
      <b-card-header class="pb-50">
        <h5> Filtrar </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="2" class="mb-md-0 mb-2">
            <b-form-group label="Número">
              <b-form-input id="number" v-model="filters.number"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-form-group label="Nombre">
              <b-form-input id="name" v-model="filters.name"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-form-group label="Encargado">
              <b-form-input id="manager" v-model="filters.manager"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" class="mt-2 text-right">
            <b-button variant="primarySpore" @click="search">
              <feather-icon icon="SearchIcon" class="mr-50"/>
              <span>Buscar</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Mostrar</label>
            <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getResponse"/>
            <label>Registros</label>
          </b-col>
          <b-col cols="12" md="6" class="custom-search d-flex justify-content-end">
            <div class="d-flex align-items-center">
              <b-button class="m-1" variant="primarySpore" @click="add()" v-b-tooltip.hover.top title="Agregar">
                <feather-icon icon="PlusCircleIcon"/>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refUserListTable" class="position-relative" :items="this.response" :fields="this.tableColumns" responsive primary-key="id" show-empty empty-text="No hay registros" tbody-tr-class="shadow-sm">

        <template #cell(number)="data">
          <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
            <span class="align-text-top text-capitalize">{{ data.item.number }}</span>
          </div>
        </template>

        <template #cell(name)="data">
          <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
            <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
          </div>
        </template>

        <template #cell(manager)="data">
          <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
            <span class="align-text-top text-capitalize">{{ data.item.manager }}</span>
          </div>
        </template>

        <template #cell(email)="data">
          <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
            <span class="align-text-top">{{ data.item.email }}</span>
          </div>
        </template>

        <template #cell(acronym)="data">
          <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
            <span class="align-text-top">{{ data.item.acronym }}</span>
          </div>
        </template>

        <template #cell(cfeZone)="data" >
          <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
            <span class="align-text-top">{{ (data.item.cfeZone != null ? data.item.cfeZone.name : '') }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row>
            <b-col cols="12" md="12" class="custom-search d-flex justify-content-end">
              <div class="d-flex align-items-center">
                <b-button v-b-tooltip.hover.left title="Editar" variant="primarySpore"
                  @click="detail(data.item.uuid)">
                  <feather-icon icon="EditIcon"/>
                </b-button>
              </div>
              &nbsp;
              <div class="d-flex align-items-center">
                <b-button v-b-tooltip.hover.top title="Eliminar" variant="danger" @click="deleteRegister(data.item.uuid)">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <!--<span class="text-muted">Showing {{ count }} of {{ totalRows }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BTable, BPagination, VBTooltip, VBToggle, BFormInput, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import request from '@/libs/request/index'
import { app } from '@/main'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    vSelect,
    BFormInput,
    BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  data () {
    const divisionUuid = this.$store.state.session.AppActiveUser().division.uuid
    return {
      show: false,
      tableColumns: [
        { key: 'number', sortable: true, label: 'Número' },
        { key: 'name', sortable: true, label: 'Nombre' },
        { key: 'manager', sortable: true, label: 'Encargado' },
        { key: 'email', sortable: true, label: 'Correo Electrónico' },
        { key: 'acronym', sortable: true, label: 'Acrónimo' },
        { key: 'cfeZone', sortable: true, label: 'Zona' },
        { key: 'actions', thClass: 'text-right', label: 'Acciones' }
      ],
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      response: [],
      count: 0,
      filters: {
        limit: 10,
        offset: 0,
        byColumn: 'id',
        byOrder: 'ASC',
        number: '',
        name: '',
        manager: '',
        divisionUuid
      }
    }
  },
  methods: {
    showFilter () {
      this.show = !this.show
    },
    async getResponse () {
      const params = {
        url: '/agencies',
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        this.response = data.data.data.rows
        this.totalRows = data.data.data.count
        this.count = data.data.data.rows.length
      })
    },
    add () {
      this.$router.push('/agencies/create')
    },
    search () {
      this.filters.offset = 0
      this.currentPage = 1
      this.getResponse()
    },
    clear () {
      this.filters.offset = 0
      this.currentPage = 1
      this.filters.name = ''
      this.filters.grouperCode = ''
      this.getResponse()
    },
    detail (uuid) {
      app.$router.push(`/Agencies/${uuid}/detail`)
    },
    async deleteRegister (uuid) {
      app.$swal({
        title: '¿Deseas eliminar el registro?',
        text: 'Esperando confirmación del usuario',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          const params = {
            url: `/agencies/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(data => {
            this.getResponse()
          })
        }
      })
    }
  },
  created () {
    this.getResponse()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
